import React, { useEffect } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';

import { Redirect, useHistory } from 'react-router-dom';


import * as Yup from 'yup';
import { Formik } from 'formik';


import { useDispatch, useSelector } from 'react-redux';

import { userLogin } from '../../../store/slice/auth/userAction'





const FirebaseLogin = ({ className, ...rest }) => {

  const dispatch = useDispatch();



  const { userToken, error } = useSelector((state) => state.user);



  const submitForm = (data) => {
    dispatch(userLogin(data))
  };


  if (userToken) {
    return <Redirect to="/" />;
  }


  return (
    <React.Fragment>
      <Formik
        initialValues={{
          email: 'slobo2@mail.com',
          passwd: 'MEGA',
          submit: null
        }}

        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          passwd: Yup.string().max(255).required('Password is required')
        })}

        onSubmit={async (values) => {
            submitForm(values)
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} className={className} {...rest}>
            <div className="form-group mb-3">
              <input
                className="form-control"
                error={touched.email && errors.email}
                label="Email Address / Username"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
              />
              {touched.email && errors.email && <small className="text-danger form-text">{errors.email}</small>}
            </div>
            <div className="form-group mb-4">
              <input
                className="form-control"
                error={touched.passwd && errors.passwd}
                label="Password"
                name="passwd"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.passwd}
              />
              {touched.password && errors.password && <small class="text-danger form-text">{errors.password}</small>}
            </div>

            {errors.submit && (
              <Col sm={12}>
                <Alert variant="danger">{errors.submit}</Alert>
              </Col>
            )}




            <Row>
              {error && (
                <Col sm={12}>
                  <Alert variant="danger">Pogrešna lozinka ili korisničko ime</Alert>
                </Col>
              )}
              <Col mt={2}>
                <Button className="btn-block" color="primary"  size="large" type="submit" variant="primary">
                  Signin
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>




    </React.Fragment>
  );
};

export default FirebaseLogin;
