import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import userReducer from './slice/auth/authSlice'

const reducers = combineReducers({
  form: formReducer,
  user: userReducer
});

export default reducers;
